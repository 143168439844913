import Cookies from 'js-cookie'

const TokenKey = 'yuebon_soft_token'
const LoginTokenKey = 'LoginToken'
const UserKey = 'UserId'
const UserName = 'UserName'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  Cookies.remove(LoginTokenKey)
  Cookies.remove(UserName)
  return Cookies.remove(TokenKey)
}

export function getCookieToken() {
  return Cookies.get(TokenKey)
}

export function getUserId() {
  return Cookies.get(UserKey)
}

export function setUserId(userId) {
  return Cookies.set(UserKey, userId)
}

export function getUserName() {
  return Cookies.get(UserName)
}

export function setUserName(userName) {
  return Cookies.set(UserName, userName)
}

// export function getLoginToken() {
//   return Cookies.get(LoginTokenKey)
// }

// export function setLoginToken(token) {
//   return Cookies.set(LoginTokenKey, token)
// }
